// Here you can add other styles

.line_dashboard_container {
  // max-width: 1230px;
  width: 100%;
  display: flex;
}

.weekly-metric-container {
}

.weekly-metric-container .line-btn-wrapper {
  margin-top: 8px;
}

.weekly-metric-container .card-container {
  margin-top: 15px;
}

.line-metrics-container {
  margin-top: 40px;
}

.line-metrics-container .card-one {
  background-color: #708090;
  // height:520px;
  margin-top: 5px;
  width: 100%;
}

.line-metrics-container .card-one .circle-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.line-metrics-container .card-one .circle-container h7 {
  text-align: center;
  color: #ebedef;
  font-size: 12px;
}

.line-metrics-container .card-one .station-item {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.line-metrics-container .card-one .station-item div {
  position: relative;
  width: 56px;
  height: 56px;
  border: 2px solid white;
  font-size: 12px;
  font-weight: 900;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 28px;
}

.line-metrics-container .card-one .station-item .orange {
  background-color: #e49b0f;
}

.line-metrics-container .card-one .station-item .blue {
  background-color: #39c0ed;
}

.piechart-container {
  display: 'flex';
  justify-content: 'space-between';
  width: '80%';
  margin: '0 auto';
}

.ag-header-cell-label {
   justify-content: center;
}

.breakdown-card {
  color: white;
  margin-top: 5px;
}
.breakdown-card .chat-wrapper {
  padding-left: 10%;
  padding-right: 10%;
  h3 {
    text-align: center;
  }
}

.overflow-table-container {
  overflow: auto;
}

.checkbox-hidden {
  position: absolute;
  opacity: 0;
}

@media (max-width: 992px) {
  .line-metrics-container .card-one .station-item div {
    width: 48px;
    height: 48px;
  }
}

@media (max-width: 767px) {
  .breakdown-card {
    .chat-wrapper {
      &:first-child {
        margin-bottom: 2rem;
      }
    }
  }
}

// line breakdown page
.line-breakdown-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;

  button {
    margin-left: 20px;
  }
}

.line-breakdown-table-container {
  overflow-x: auto;
}

// Login page
.login-cardgroup {
  .card {
    margin-bottom: 0;
  }

  
  .login-card {
    border-bottom-left-radius: 0.25rem;
  }

  @media (max-width: 567px) {
    .login-card {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .register-card {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.page_title_header {
  font-size:x-large;
}

.property-list-container {
  display: block;
  width:13%;
  float:right;
  margin-left:10;
}

.property-list-items {

}

.property-text-size {
  font-size:17px;
}

.property-undernotice-text-size {
  font-size:11px;
}

.dashboard-main-container {
  width:82%
}

.flex-box {
  display: flex;
}

@media only screen and (max-width:768px){
  .property-list-container {
    display:flex;
    width:100%
  }
  .property-list-items {
    flex:18%;
    width:0%;
    padding:5px
  }

  .dashboard-main-container {
    width:100%
  }
}

@media only screen and (max-width:425px){
  .page_title_header {
    font-size:medium;
  }
  .property-text-size {
    font-size:10px;
  }
  .property-undernotice-text-size {
    font-size:8px;
  }
}

#grid-theme-wrapper.red-theme .ag-theme-alpine .ag-row-selected {
  background-color: rgba(220, 53, 69, 0.3);
}

/* RANGE SELECTION */
#grid-theme-wrapper.red-theme
  .ag-theme-alpine
  .ag-cell-range-selected:not(.ag-cell-focus),
#grid-theme-wrapper.red-theme
  .ag-theme-alpine
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-single-cell:not(.ag-cell-inline-editing) {
  background-color: rgba(220, 53, 69, 0.2);
}

/* RANGE SELECTION ITERSECTION LEVELS */

#grid-theme-wrapper.red-theme
  .ag-theme-alpine
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-selected-1:not(.ag-cell-inline-editing) {
  background-color: rgba(220, 53, 69, 0.2);
}

#grid-theme-wrapper.red-theme
  .ag-theme-alpine
  .ag-cell-range-selected-1:not(.ag-cell-focus),
grid-theme-wrapper.red-theme
  .ag-theme-alpine
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-selected-1 {
  background-color: rgba(220, 53, 69, 0.2);
}

#grid-theme-wrapper.red-theme
  .ag-theme-alpine
  .ag-cell-range-selected-2:not(.ag-cell-focus),
grid-theme-wrapper.red-theme
  .ag-theme-alpine
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-selected-2 {
  background-color: rgba(220, 53, 69, 0.36);
}

#grid-theme-wrapper.red-theme
  .ag-theme-alpine
  .ag-cell-range-selected-3:not(.ag-cell-focus),
grid-theme-wrapper.red-theme
  .ag-theme-alpine
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-selected-3 {
  background-color: rgba(220, 53, 69, 0.488);
}

#grid-theme-wrapper.red-theme
  .ag-theme-alpine
  .ag-cell-range-selected-4:not(.ag-cell-focus),
grid-theme-wrapper.red-theme
  .ag-theme-alpine
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-selected-4 {
  background-color: rgba(220, 53, 69, 0.5904);
}

/* ===               === */
/*                       */
/*       GREEN THEME     */
/*                       */
/* ===               === */

/* ROW SELECTION */
#grid-theme-wrapper.green-theme .ag-theme-alpine .ag-row-selected {
  background-color: rgba(40, 167, 69, 0.3);
}

/* RANGE SELECTION */

#grid-theme-wrapper.green-theme
  .ag-theme-alpine
  .ag-cell-range-selected:not(.ag-cell-focus),
#grid-theme-wrapper.green-theme
  .ag-theme-alpine
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-single-cell:not(.ag-cell-inline-editing) {
  background-color: rgba(40, 167, 69, 0.2);
}

/* RANGE SELECTION ITERSECTION LEVELS */

/* when range selected cell is focused */
#grid-theme-wrapper.green-theme
  .ag-theme-alpine
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-selected-1:not(.ag-cell-inline-editing) {
  background-color: rgba(40, 167, 69, 0.2);
}

/* whe not focused */
#grid-theme-wrapper.green-theme
  .ag-theme-alpine
  .ag-cell-range-selected-1:not(.ag-cell-focus),
grid-theme-wrapper.green-theme
  .ag-theme-alpine
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-selected-1 {
  background-color: rgba(40, 167, 69, 0.2);
}

#grid-theme-wrapper.green-theme
  .ag-theme-alpine
  .ag-cell-range-selected-2:not(.ag-cell-focus),
grid-theme-wrapper.green-theme
  .ag-theme-alpine
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-selected-2 {
  background-color: rgba(40, 167, 69, 0.36);
}

#grid-theme-wrapper.green-theme
  .ag-theme-alpine
  .ag-cell-range-selected-3:not(.ag-cell-focus),
grid-theme-wrapper.green-theme
  .ag-theme-alpine
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-selected-3 {
  background-color: rgba(40, 167, 69, 0.488);
}

.scrolldiv::-webkit-scrollbar {
  display: none;
}

.scrolldiv {
  overflow-y: scroll
}

.underline {
  width: 6rem;
  height: 0.25rem;
  background: #05B8CC;
  margin-left: auto;
  margin-right: auto;
}

#grid-theme-wrapper.green-theme
  .ag-theme-alpine
  .ag-cell-range-selected-4:not(.ag-cell-focus),
grid-theme-wrapper.green-theme
  .ag-theme-alpine
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-selected-4 {
  background-color: rgba(40, 167, 69, 0.5904);
}

$display-font-sizes: (
  1: 4rem,
  2: 4rem,
  3: 3.5rem,
  4: 3rem,
  5: 2.8rem,
  6: 2.2rem,
);

$display-font-weight: 300;
$display-line-height: $headings-line-height;

$headings-margin-bottom: $spacer * 0.5;
$headings-font-family: null;
$headings-font-style: null;
$headings-font-weight: 500;
$headings-line-height: 1;
$headings-color: unset;

$lead-font-size: $font-size-base * 1.1;
$lead-font-weight: 300;

$small-font-size: 0.7em;

$sub-sup-font-size: 0.6em;

$text-high-emphasis: $high-emphasis;
$text-medium-emphasis: $medium-emphasis;
$text-disabled: $disabled;
$text-muted: $disabled;

$text-high-emphasis-inverse: $high-emphasis-inverse;
$text-medium-emphasis-inverse: $medium-emphasis-inverse;
$text-disabled-inverse: $disabled-inverse;

$initialism-font-size: $small-font-size;

$blockquote-margin-y: $spacer;
$blockquote-font-size: $font-size-base * 1;
$blockquote-footer-color: $gray-600;
$blockquote-footer-font-size: $small-font-size;

$hr-margin-y: $spacer;
$hr-color: inherit;
$hr-height: $border-width;
$hr-opacity: 0.25;

$vr-color: inherit;
$vr-width: $border-width;
$vr-opacity: 0.25;

$legend-margin-bottom: 0.5rem;
$legend-font-size: 1rem;
$legend-font-weight: null;

$mark-padding: 0.2em;

$dt-font-weight: $font-weight-bold;

$nested-kbd-font-weight: $font-weight-bold;

$list-inline-padding: 0.5rem;

$mark-bg: #fcf8e3;