// If you want to override variables do it here
//@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import '@coreui/coreui/scss/coreui';

// Import Chart.js custom tooltips styles
@import '@coreui/chartjs/scss/coreui-chartjs';

@import 'layout';

@import 'custom';

// If you want to add custom CSS you can put it here.

html,
body {
  width: 100%;
  overflow-x: hidden;
}

body {
  font: 0.8rem/1.2 var(--cui-font-sans-serif);
  position:relative;
}
a {
  color: var(--cui-blue);
}

.sidebar-nav::-webkit-scrollbar {
  display:none;
}
